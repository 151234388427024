import * as React from 'react'
import { connect } from 'react-redux'
import store from '../../store'

import { Container, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

import TaskList from './TaskList'
import { getUserTaskLists } from '../../actions/taskLists';

function TaskLists(props) {
    const familyId = props.family.family_id

    React.useEffect(() => {
        let data = {}
        props.justMine ? data = { user_id: props.user.user_id, family_id: familyId } : data = { family_id: familyId }
        store.dispatch(getUserTaskLists(data))
    }, [props.justMine, familyId, props.user.user_id])

    return (
        <React.Fragment>

            <Container maxWidth="sm" sx={{ mb: 8 }}>
                <Grid container spacing={2}>
                    {props.taskLists.length > 0
                        ? props.taskLists.map(taskList =>
                            <Grid
                                key={taskList.task_list_id}
                                xs={6}
                            >

                                <TaskList
                                    taskListId={taskList.task_list_id}
                                    title={taskList.title}
                                    users={taskList.collaborators}
                                    tasks={taskList.tasks}
                                />

                            </Grid>
                        ) : <Typography variant={'body2'}>Organise your tasks by creating "Task lists"</Typography>
                    }
                </Grid>

            </Container>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    user: state.authReducer.user,
    taskLists: state.taskListsReducer.taskLists,
    loading: state.taskListsReducer,
    justMine: state.justMineReducer.justMine,
    family: state.familyReducer.family,
})

export default connect(mapStateToProps, { getUserTaskLists })(TaskLists)