import {
  TASKS_LOADING,
  TASKS_LOADED,
  TASKS_SUCCESS,
  TASKS_FAILED,
  TASK_UPDATING,
  TASK_UPDATED,
  TASK_ADDING,
  TASK_ADDED,
  TASK_DELETING,
  TASK_DELETED,
} from '../actions/types'

const initialState = {
  isLoading: false,
  isLoaded: false,
  isSuccessful: false,
  tasks: [],
  taskAdding: false,
  taskDeleting: false,
  taskUpdating: false,
}

export default function taskReducers(state = initialState, action) {
  switch (action.type) {
    case TASKS_LOADING:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        isSuccessful: false,
      }
    case TASKS_LOADED:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        tasks: action.payload
      }
    case TASKS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        isLoaded: false,
        isSuccessful: true,
      }
    case TASKS_FAILED:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        isSuccessful: false,
        tasks: [],
      }
    case TASK_UPDATING:
      return {
        ...state,
        taskUpdating: true,
      }
    case TASK_UPDATED:
      return {
        ...state,
        taskUpdating: false,
      }
    case TASK_ADDING:
      return {
        ...state,
        // ...action.payload,
        taskAdding: true,
      }
    case TASK_ADDED:
      return {
        ...state,
        // ...action.payload,
        taskAdding: false,
      }
    case TASK_DELETING:
      return {
        ...state,
        // ...action.payload,
        taskDeleting: true,
      }
    case TASK_DELETED:
      return {
        ...state,
        // ...action.payload,
        taskDeleting: false,
      }
    default:
      return state
  }
}