import { combineReducers } from 'redux'
import auth from './auth'
import tasks from './tasks'
import taskLists from './taskLists'
import justMine from './justMine'
import family from './family'

export default combineReducers({
  authReducer: auth,
  tasksReducer: tasks,
  taskListsReducer: taskLists,
  justMineReducer: justMine,
  familyReducer: family,
})