import React, { useState } from 'react';
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom';

import { Avatar, Box, Button, Checkbox, Container, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select, TextField, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment';

import { addTask } from '../../actions/tasks';
import { getUserTaskLists } from '../../actions/taskLists';
import PageHeader from '../common/PageHeader';

function AddTask(props) {
    React.useEffect(() => {
        let data = { family_id: props.family.family_id }
        props.getUserTaskLists(data)
    }, [props.family])

    const familyMembers = []
    props.family.members.map(member => familyMembers.push(member))

    const [assignees, setAssignees] = useState([])
    const [title, setTitle] = useState('');
    const [date, setDate] = useState(null);
    const [time, setTime] = useState(null);
    const [description, setDescription] = useState('');
    const [taskLists, setTasksList] = useState([]);

    const handleTaskListChange = (event) => {
        const {
            target: { value },
        } = event;
        setTasksList(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleAssigneeChange = (event) => {
        setAssignees(event.target.value);
    };

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        let newDate
        if (date != null) {
            let dateInput = moment.utc(new Date(date))
            let newDateFormatted = dateInput.parseZone(dateInput.toISOString())
            newDate = newDateFormatted.local().format().slice(0, 10)
        } else {
            newDate = null
        }

        let newTime
        if (time != null) {
            let timeInput = new Date(time)
            newTime = timeInput.toISOString().slice(11, 16)
        } else {
            newTime = null
        }

        const data = {
            title: title,
            families: [props.family.family_id],
            assignees: assignees,
            description: description,
            due_date: newDate,
            due_time: newTime,
            task_lists: taskLists.length > 0 ? taskLists : []
        }
        let result = await props.addTask(data)
        navigate('/')

        // console.log(data)
    };

    return (
        <React.Fragment>
            <PageHeader header="Add a new task" />

            <Container component='main' maxWidth='sm'>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        {props.user.families.length > 1 ? <Typography>Family: {props.family.name}</Typography> : undefined}
                        {/* TITLE */}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="taskTitle"
                            label="Task"
                            name="taskTitle"
                            autoComplete="Task"
                            autoFocus
                            onChange={e => setTitle(e.target.value)}
                            value={title}
                        />

                        {/* ASSIGNEES */}
                        <FormControl fullWidth>
                            <InputLabel id="assignees-select-label">Assignees</InputLabel>
                            <Select
                                labelId="assignees-select-label"
                                id="assignees-select"
                                multiple
                                value={assignees}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map(item =>
                                                <Avatar
                                                    key={familyMembers.find(({ user_id: v }) => v === item).user_id}
                                                    alt={familyMembers.find(({ user_id: v }) => v === item).username}
                                                    src={'assignee.img'}
                                                    sx={{ width: 30, height: 30 }}
                                                />
                                        )}
                                    </Box>
                                )}
                                label="Assignees"
                                onChange={handleAssigneeChange}
                            >
                                {familyMembers.map(member =>
                                    <MenuItem key={member.user_id} value={member.user_id}>
                                        <Checkbox checked={assignees.indexOf(member.user_id) > -1} />
                                        <Avatar
                                            key={member.user_id}
                                            alt={member.username}
                                            src={'assignee.img'}
                                            sx={{ width: 30, height: 30, mr: 1 }}
                                        />
                                        <ListItemText primary={member.username} />
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>

                        {/* DATE AND TIME */}
                        <Grid container sx={{ mt: 2 }}>
                            {/* DATE */}
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Date"
                                        value={date}
                                        onChange={(newValue) => {
                                            setDate(newValue);
                                        }}
                                        inputFormat='dd/MM/yyyy'
                                        renderInput={(params) => <TextField {...params} />}
                                        showDaysOutsideCurrentMonth={true}
                                    />
                                </LocalizationProvider>
                            </Grid>

                            {/* TIME */}
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        label="Time"
                                        ampm={false}
                                        value={time}
                                        onChange={(newValue) => {
                                            setTime(newValue);
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>

                        {/* DESCRIPTION */}
                        <TextField
                            margin="normal"
                            fullWidth
                            multiline
                            rows={3}
                            id="taskDescription"
                            label="Description"
                            name="taskDescription"
                            autoComplete="Description"
                            onChange={e => setDescription(e.target.value)}
                            value={description}
                        />

                        {/* TASK LISTS */}
                        <FormControl fullWidth>
                            <InputLabel id="task-list-select-label">Task list</InputLabel>
                            <Select
                                labelId="task-list-select-label"
                                id="task-list-select"
                                multiple
                                value={taskLists}
                                renderValue={(selected) => selected.map(item => (props.taskLists.find(({ task_list_id: v }) => v === item).title)).join(', ')}
                                label="Task list"
                                onChange={handleTaskListChange}
                            >
                                {props.taskLists.map(taskList =>
                                    <MenuItem key={taskList.task_list_id} value={taskList.task_list_id}>
                                        <Checkbox checked={taskLists.indexOf(taskList.task_list_id) > -1} />
                                        <ListItemText primary={taskList.title} />
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>

                        {/* CREATE BUTTON */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, bgcolor: '#000', '&:hover': { backgroundColor: '#333' } }}
                        >
                            Create
                        </Button>
                    </Box>
                </Box>
            </Container>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    user: state.authReducer.user,
    taskLists: state.taskListsReducer.taskLists,
    family: state.familyReducer.family,
})

export default connect(mapStateToProps, { addTask, getUserTaskLists })(AddTask)