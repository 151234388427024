import axios from 'axios'
import {
  TASKS_LOADING,
  TASKS_LOADED,
  TASKS_SUCCESS,
  TASKS_FAILED,
  TASK_UPDATING,
  TASK_UPDATED,
  TASK_ADDING,
  TASK_ADDED,
  TASK_DELETING,
  TASK_DELETED,
} from './types'
import { tokenConfig } from './auth'
import { toast } from 'react-toastify';

const BaseUrl = 'https://vesta-social.herokuapp.com/api/'

// GET USER TASKS
export const getUserTasks = (props) => (dispatch, getState) => {

  dispatch({ type: TASKS_LOADING })

  axios.post(BaseUrl + 'tasks/get/', props, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TASKS_LOADED,
        payload: res.data,
      })
      dispatch({
        type: TASKS_SUCCESS,
      })
    }).catch(err => {
      console.log(err)
      dispatch({
        type: TASKS_FAILED
      })
    })
}

// UPDATE TASK
export const updateTask = (props) => (dispatch, getState) => {

  dispatch({ type: TASK_UPDATING })

  // Request Body
  axios.post(BaseUrl + 'tasks/update/', props, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TASK_UPDATED
      })
      // toast.success('Task updated')
    }).catch(err => {
      console.log(err)
    })
}

// ADD TASK
export const addTask = (props) => (dispatch, getState) => {

  dispatch({ type: TASK_ADDING })

  // Request body
  axios.post(BaseUrl + 'tasks/add/', props, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TASK_ADDED
      })
      // dispatch({
      //   type: TASKS_SUCCESS
      // })
      toast.success('Task added')

    }).catch(err => {
      console.log(err)
    })
}

// DELETE TASK
export const deleteTask = (props) => (dispatch, getState) => {

  dispatch({ type: TASK_DELETING })

  // Request Body
  axios.post(BaseUrl + 'tasks/delete/', props, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TASK_DELETED
      })
      toast.success('Task deleted')
    }).catch(err => {
      console.log(err)
    })
}