import axios from 'axios'
import {
  TASK_LISTS_LOADING,
  TASK_LISTS_LOADED,
  TASK_LISTS_SUCCESS,
  TASK_LISTS_FAILED,
} from './types'
import { tokenConfig } from './auth'
import { toast } from 'react-toastify'

const BaseUrl = 'https://vesta-social.herokuapp.com/api/'

// GET USER TASK LISTS
export const getUserTaskLists = (props) => (dispatch, getState) => {

  dispatch({ type: TASK_LISTS_LOADING })

  axios.post(BaseUrl + 'tasks/task_lists/get/', props, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: TASK_LISTS_LOADED,
        payload: res.data,
      })
      dispatch({
        type: TASK_LISTS_SUCCESS,
      })
    }).catch(err => {
      console.log(err)
      dispatch({
        type: TASK_LISTS_FAILED
      })
    })
}

// ADD TASK LIST
export const addTaskList = (props) => (dispatch, getState) => {

  // Request body
  axios.post(BaseUrl + 'tasks/task_lists/add/', props, tokenConfig(getState))
    .then(res => {
      toast.success('Task list added')

    }).catch(err => {
      console.log(err)
    })
}

// UPDATE TASK LIST
export const updateTaskList = (props) => (dispatch, getState) => {

  // Request Body
  axios.post(BaseUrl + 'tasks/task_lists/update/', props, tokenConfig(getState))
    .then(res => {
    }).catch(err => {
    console.log(err)
  })
}

// DELETE TASK
export const deleteTaskList = (props) => (dispatch, getState) => {

  // Request Body
  axios.post(BaseUrl + 'tasks/task_lists/delete/', props, tokenConfig(getState))
    .then(res => {
      toast.success('Task list deleted')
    }).catch(err => {
      console.log(err)
    })
}
