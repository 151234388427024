import React, { useState } from 'react';
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom';

import { Box, Button, Container, TextField, Typography } from '@mui/material';

import { addTaskList } from '../../actions/taskLists';
import PageHeader from '../common/PageHeader';

function AddTaskList(props) {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = { title: title, collaborators: [props.user.user_id], families: [props.family.family_id] }
        props.addTaskList(data)
        navigate('/TaskLists')
        // console.log(data)
    };

    return (
        <React.Fragment>
            <PageHeader header="Add a new list" />

            <Container component='main' maxWidth='sm'>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >

                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        {props.user.families.length > 1 ? <Typography>Family: {props.family.name}</Typography> : undefined}
                        {/* TITLE */}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="taskListTitle"
                            label="Task list"
                            name="taskListTitle"
                            autoComplete="Task"
                            autoFocus
                            onChange={e => setTitle(e.target.value)}
                            value={title}
                        />

                        {/* DESCRIPTION */}
                        <TextField
                            margin="normal"
                            fullWidth
                            multiline
                            rows={3}
                            id="taskListDescription"
                            label="Description"
                            name="taskListDescription"
                            autoComplete="Description"
                            onChange={e => setDescription(e.target.value)}
                            value={description}
                        />

                        {/* CREATE BUTTON */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, bgcolor: '#000', '&:hover': { backgroundColor: '#333' } }}
                        >
                            Create
                        </Button>
                    </Box>
                </Box>
            </Container>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    user: state.authReducer.user,
    family: state.familyReducer.family,
})

export default connect(mapStateToProps, { addTaskList })(AddTaskList)