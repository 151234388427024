import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { connect } from 'react-redux'

import Loading from './Loading'

import Header from '../main/Header'
import BottomNav from '../main/BottomNav'

function PrivateRoute({ children, auth }) {
  if (auth.isLoading) {
    return <Loading />
  }

  if (!auth.isLoading && !auth.isAuthenticated) {
    return <Navigate to={'/login'} />
  } else {
    return (
      <React.Fragment>
        <Header />
        <Outlet />
        <BottomNav />
      </React.Fragment>)
  }
}

const mapStateToProps = state => ({
  auth: state.authReducer
})

export default connect(mapStateToProps)(PrivateRoute)