import { Container } from '@mui/material';
import * as React from 'react';

function Section(props) {
    return (
        <React.Fragment>
            <Container maxWidth={props.maxWidth}>
                {props.children}
            </Container>
        </React.Fragment>
    )
}

export default Section