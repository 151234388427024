import * as React from 'react'
import { connect } from 'react-redux'
import store from '../../store'

import TasksHeader from './TasksHeader';
import Tasks from './Tasks';
import Loading from '../common/Loading'

import { getUserTasks } from '../../actions/tasks'
import { getUserTaskLists } from '../../actions/taskLists'


function TasksView(props) {
    const familyId = props.family.family_id

    React.useEffect(() => {
        let data = {}
        props.justMine ? data = { user_id: props.user.user_id, family_id: familyId } : data = { family_id: familyId }
        store.dispatch(getUserTasks(data))
        store.dispatch(getUserTaskLists(data))
    }, [props.justMine, familyId, props.user.user_id])

    const overdue = { data: props.tasks.filter(task => task.status === 'Overdue'), title: 'Overdue', open: false }
    const today = { data: props.tasks.filter(task => task.status === 'Today'), title: 'Today', open: true }
    const future = { data: props.tasks.filter(task => task.status === 'Upcoming' || task.status === 'Future' || task.status === 'Due soon'), title: 'Future', open: false }
    const completed = { data: props.tasks.filter(task => task.is_completed), title: 'Completed', open: false }

    const sortByStatus = [overdue, today, future, completed]

    if (!props.loading.isSuccessful) {
        return <Loading />
    } else {
        return (
            <React.Fragment>
                <TasksHeader
                    header={props.family.name + "'s tasks"}
                />
                <Tasks sortBy={sortByStatus} familyId={familyId} />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    user: state.authReducer.user,
    tasks: state.tasksReducer.tasks,
    loading: state.tasksReducer,
    justMine: state.justMineReducer.justMine,
    family: state.familyReducer.family,
})

export default connect(mapStateToProps, {})(TasksView)