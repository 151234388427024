import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Paper, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'
import ListIcon from '@mui/icons-material/List';

const TaskList = (props) => {

    return (
        <Paper
            sx={{
                boxShadow: 3,
                borderBottom: 1,
                bgcolor: '#EEE',
                p: 1,
                mb: 1,
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: 11,
            }}
            square
        >
            <Grid
                container
                alignItems='center'
                component={Link}
                to={`/TaskList/${props.taskListId}`}
            >

                <Grid xs={12}>
                    <Typography noWrap sx={{ fontSize: 14, pl: 1 }}>{props.title}</Typography>
                </Grid>

                <Grid xs={3}>
                    <ListIcon />
                </Grid>

                <Grid xs={9}>
                    <Box>
                        <Typography sx={{ fontSize: 14 }}>{props.tasks.length}</Typography>
                    </Box>
                </Grid>

            </Grid>
        </Paper>
    )
}

export default TaskList