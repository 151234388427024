import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';

import { Avatar, Box, Button, Container, Divider, MenuItem, Typography } from '@mui/material'
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
// import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import { logout } from '../../actions/auth'
import PageHeader from '../common/PageHeader';
import Section from '../common/Section';
import SectionHeaderCollapse from '../common/SectionHeaderCollapse';

function MyAccount(props) {
    return (
        <React.Fragment>
            <PageHeader header="Profile" />

            <Container component='main' maxWidth='sm'>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar
                        alt={props.user.username}
                        src={'assignee.img'}
                        sx={{ width: 120, height: 120 }}
                    />
                    <Typography sx={{ mt: 1 }}>{props.user.first_name} {props.user.last_name}</Typography>
                    <Typography sx={{ mt: 1 }}>{props.user.email}</Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                        mt: 3,
                    }}
                >
                    <Typography variant={'h6'}>Families:</Typography>
                    <Section maxWidth='sm'>
                        {props.user.families.map(family =>
                            <SectionHeaderCollapse
                                key={family.family_id}
                                title={family.name}
                                data={family.members}
                                open={false}
                            >
                                <Section key={family.family_id} maxWidth='sm'>
                                    {family.members.map(member =>
                                        <MenuItem key={member.user_id}>
                                            <Avatar
                                                alt={member.username}
                                                src={'assignee.img'}
                                                sx={{ width: 30, height: 30 }}
                                            />
                                            <Typography
                                                sx={{ ml: 1 }}>
                                                {member.username}
                                            </Typography>
                                        </MenuItem>
                                    )}
                                    <Divider />
                                    <MenuItem>
                                        <PersonAddIcon />
                                        <Typography sx={{ ml: 1 }}>Add member</Typography>
                                    </MenuItem>
                                </Section>
                            </SectionHeaderCollapse>
                        )}
                        <Divider />
                        <MenuItem
                            component={Link}
                            to={'/Family/Add'}
                        >
                            <GroupAddIcon />
                            <Typography sx={{ ml: 1 }}>Add family</Typography>
                        </MenuItem>
                    </Section>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Button
                        onClick={props.logout}
                        variant="contained"
                        fullWidth
                        sx={{
                            mt: 3,
                            mb: 2,
                            bgcolor: '#b30000',
                            '&:hover': {
                                backgroundColor: '#333'
                            }
                        }}
                    >
                        Logout
                    </Button>
                </Box>
            </Container>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    user: state.authReducer.user
})

export default connect(mapStateToProps, { logout })(MyAccount)