import axios from 'axios'
import {
    FAMILY_LOADING,
    FAMILY_LOADED,
    FAMILY_SUCCESS,
    FAMILY_FAILED,
} from './types'
import { loadUser, tokenConfig } from './auth'
import { toast } from 'react-toastify';

const BaseUrl = 'https://vesta-social.herokuapp.com/api/'

// GET FAMILY
export const getFamily = (props) => (dispatch, getState) => {

  dispatch({ type: FAMILY_LOADING })

  axios.post(BaseUrl + 'accounts/family/get/', props, tokenConfig(getState))
    .then(res => {
      dispatch({
        type: FAMILY_LOADED,
        payload: res.data,
      })
      dispatch({
        type: FAMILY_SUCCESS,
      })
    }).catch(err => {
      console.log(err)
      dispatch({
        type: FAMILY_FAILED
      })
      toast.error(err.message)
    })
}

// ADD FAMILY
export const addFamily = (props) => (dispatch, getState) => {

  axios.post(BaseUrl + 'accounts/family/add/', props, tokenConfig(getState))
    .then(res => {
      dispatch(loadUser())
      toast.success("Family added")
    }).catch(err => {
      toast.error(err.message)
    })
}