import { Box, Container, Typography } from '@mui/material'
import React from 'react'

export default function PageHeader(props) {
    return (
        <Container maxWidth="sm" sx={{ py: 2 }}>
            <Box>
                <Typography component="h1" variant="h5">{props.header}</Typography>
            </Box>
        </Container>
    )
}