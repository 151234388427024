import React, { useState } from 'react';
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
import store from '../../store';
import { toast } from 'react-toastify';
import moment from 'moment';

import { Avatar, Box, Button, Checkbox, Container, FormControl, Grid, IconButton, InputLabel, ListItemText, MenuItem, Select, TextField } from '@mui/material';
import DeleteForever from '@mui/icons-material/DeleteOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { deleteTask, updateTask } from '../../actions/tasks';
import { getUserTaskLists } from '../../actions/taskLists';

function TaskDetailCard(props) {
    const { taskId } = useParams()

    React.useEffect(() => {
        let data = { family_id: props.family.family_id }
        props.getUserTaskLists(data)
    }, [])

    const task = props.tasks.filter((task) => task.task_id === taskId)[0]

    const familyMembers = []
    props.family.members.map(member => familyMembers.push(member))

    const [assignees, setAssignees] = useState(task.assignees ? task.assignees : [])
    const [isCompleted, setIsCompleted] = useState(task.is_completed)
    const [title, setTitle] = useState(task.title ? task.title : '');
    const [date, setDate] = useState(task.due_date ? task.due_date : null);
    const [time, setTime] = useState(task.due_time ? '2021-05-22T' + task.due_time + 'Z' : null);
    const [description, setDescription] = useState(task.description ? task.description : '');
    const [taskLists, setTaskList] = useState(task.task_lists ? task.task_lists : []);

    const navigate = useNavigate();

    const handleTaskListChange = (event) => {
        setTaskList(event.target.value);
    };

    const handleAssigneeChange = (event) => {
        setAssignees(event.target.value);
    };

    const handleCompletedClick = () => {
        setIsCompleted(!isCompleted)
        const data = { task_id: task.task_id, title: title, is_completed: !isCompleted }
        store.dispatch(updateTask(data))
        !isCompleted === true ? toast.success('Congratulations!!') : toast.error('Oh no!')
    }

    const handleDeleteClick = () => {
        const data = { task_id: task.task_id }
        store.dispatch(deleteTask(data))
        navigate('/')
    }


    const handleSubmit = (event) => {
        event.preventDefault();

        let newDate
        if (date != null) {
            let dateInput = moment.utc(new Date(date))
            let newDateFormatted = dateInput.parseZone(dateInput.toISOString())
            newDate = newDateFormatted.local().format().slice(0, 10)
        } else {
            newDate = null
        }

        let newTime
        if (time != null) {
            let timeInput = new Date(time)
            newTime = timeInput.toISOString().slice(11, 16)
        } else {
            newTime = null
        }

        const data = {
            task_id: task.task_id,
            title: title,
            families: [props.family.family_id],
            assignees: assignees,
            description: description,
            due_date: newDate,
            due_time: newTime,
            task_lists: taskLists.length > 0 ? taskLists : []
        }
        props.updateTask(data)
        navigate('/')
    };

    return (
        <React.Fragment>
            <Container component='main' maxWidth='sm'>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >

                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 2 }}>
                        {/* DELETE BUTTON */}
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                            <IconButton onClick={handleDeleteClick} sx={{color: '#b30000'}}>
                                <DeleteForever />
                            </IconButton>
                        </Box>
                        
                        {/* ASSIGNEES */}
                        <FormControl fullWidth>
                            <InputLabel id="assignees-select-label">Assignees</InputLabel>
                            <Select
                                labelId="assignees-select-label"
                                id="assignees-select"
                                variant='standard'
                                multiple
                                value={assignees}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map(item =>
                                            <Avatar
                                                key={familyMembers.find(({ user_id: v }) => v === item).user_id}
                                                alt={familyMembers.find(({ user_id: v }) => v === item).username}
                                                src={'assignee.img'}
                                                sx={{ width: 30, height: 30 }}
                                            />
                                        )}
                                    </Box>
                                )}
                                label="Assignees"
                                onChange={handleAssigneeChange}
                            >
                                {familyMembers.map(member =>
                                    <MenuItem key={member.user_id} value={member.user_id}>
                                        <Checkbox checked={assignees.indexOf(member.user_id) > -1} />
                                        <Avatar
                                            key={member.user_id}
                                            alt={member.username}
                                            src={'assignee.img'}
                                            sx={{ width: 30, height: 30, mr: 1 }}
                                        />
                                        <ListItemText primary={member.username} />
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>

                        {/* HEADER */}
                        <Grid container alignItems={'center'} sx={{ mt: 1 }}>
                            {/* COMPLETED */}
                            <Grid item xs={1} >
                                <IconButton onClick={handleCompletedClick}>
                                    {isCompleted ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />}
                                </IconButton>
                            </Grid>
                            <Grid item xs={11} sx={{ pl: 1.5 }}>
                                {/* TITLE */}
                                <TextField
                                    margin="normal"
                                    variant='standard'
                                    required
                                    fullWidth
                                    id="taskTitle"
                                    name="taskTitle"
                                    autoComplete="Task"
                                    // autoFocus
                                    onChange={e => setTitle(e.target.value)}
                                    value={title}
                                />
                            </Grid>
                        </Grid>

                        {/* DATE AND TIME */}
                        <Grid container sx={{ mt: 2 }}  >
                            {/* DATE */}
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                        label="Date"
                                        value={date}
                                        onChange={(newValue) => {
                                            setDate(newValue);
                                        }}
                                        inputFormat='dd/MM/yyyy'
                                        renderInput={(params) => <TextField {...params} />}
                                        showDaysOutsideCurrentMonth={true}
                                    />
                                </LocalizationProvider>
                            </Grid>

                            {/* TIME */}
                            <Grid item xs={6}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <TimePicker
                                        label="Time"
                                        ampm={false}
                                        value={time}
                                        onChange={(newValue) => {
                                            setTime(newValue);
                                        }}
                                        inputFormat='HH:mm:ss'
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>

                        {/* RECURRENCE */}
                        

                        {/* DESCRIPTION */}
                        <TextField
                            margin="normal"
                            fullWidth
                            multiline
                            rows={3}
                            id="taskDescription"
                            label="Description"
                            name="taskDescription"
                            autoComplete="Description"
                            onChange={e => setDescription(e.target.value)}
                            value={description}
                        />

                        {/* TASK LISTS */}
                        <FormControl fullWidth sx={{ mt: 1 }}>
                            <InputLabel id="task-list-select-label">Task list</InputLabel>
                            <Select
                                labelId="task-list-select-label"
                                id="task-list-select"
                                multiple
                                value={taskLists}
                                renderValue={(selected) => selected.map(item => (props.taskLists.find(({ task_list_id: v }) => v === item).title)).join(', ')}
                                label="Task list"
                                onChange={handleTaskListChange}
                            >
                                {props.taskLists.map(taskList =>
                                    <MenuItem key={taskList.task_list_id} value={taskList.task_list_id}>
                                        <Checkbox checked={taskLists.indexOf(taskList.task_list_id) > -1} />
                                        <ListItemText primary={taskList.title} />
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>

                        {/* UPDATE BUTTON */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, bgcolor: '#000', '&:hover': { backgroundColor: '#333' } }}
                        >
                            Save
                        </Button>
                    </Box>
                </Box>
            </Container>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    user: state.authReducer.user,
    tasks: state.tasksReducer.tasks,
    taskLists: state.taskListsReducer.taskLists,
    family: state.familyReducer.family,
})

export default connect(mapStateToProps, { updateTask, getUserTaskLists })(TaskDetailCard)