import React, { useState } from 'react';
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { Box, Button, Container, TextField, Typography } from '@mui/material'

import { login } from '../../actions/auth';

function Login(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        props.login(email, password)
    };

    if (props.isAuthenticated) {
        return <Navigate to={'/'} />
    }
    return (
        <React.Fragment>
            <Container component='main' maxWidth='xs'>
                <Box
                    sx={{
                        marginTop: 10,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 5 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={e => setEmail(e.target.value)}
                            value={email}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={e => setPassword(e.target.value)}
                            value={password}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, bgcolor: '#000', '&:hover': { backgroundColor: '#333' } }}
                        >
                            Sign In
                        </Button>

                        {/* <Typography variant="body2" component="p">
                            Don't have an account? <Link to={'/register'}>Register</Link>
                        </Typography> */}
                    </Box>
                </Box>
            </Container>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.authReducer.isAuthenticated
})

export default connect(mapStateToProps, { login })(Login)