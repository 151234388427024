import React from 'react'
import { Link } from 'react-router-dom';

import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material'
import ListIcon from '@mui/icons-material/List';
import SplitscreenIcon from '@mui/icons-material/Splitscreen';
import AddMenu from '../common/AddMenu'

export default function BottomNav(props) {
    return (
        <React.Fragment>
            <Box sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                boxShadow: 5,
                backgroundColor: '#fff',
                // borderTop: 1,
            }}>
                <BottomNavigation>
                    <BottomNavigationAction component={Link} to={'/TaskLists'} label="Task Lists" icon={<SplitscreenIcon />} />
                    <BottomNavigationAction component={Link} to={'/'} label="Tasks" icon={<ListIcon />} />
                    <AddMenu />
                </BottomNavigation>
            </Box>
        </React.Fragment>
    )
}