import {
    TASK_LISTS_LOADING,
    TASK_LISTS_LOADED,
    TASK_LISTS_SUCCESS,
    TASK_LISTS_FAILED,
  } from '../actions/types'
  
  const initialState = {
    isLoading: false,
    isLoaded: false,
    isSuccessful: false,
    taskLists: [],
  }
  
  export default function taskListReducers (state = initialState, action) {
    switch (action.type) {
      case TASK_LISTS_LOADING:
        return {
          ...state,
          isLoading: true,
        }
      case TASK_LISTS_LOADED:
        return {
          ...state,
          isLoading: false,
          isLoaded: true,
          taskLists: action.payload
        }
      case TASK_LISTS_SUCCESS:
        return {
          ...state,
          ...action.payload,
          isLoaded: false,
          isSuccessful: true,
        }
      case TASK_LISTS_FAILED:
        return {
          ...state,
          isLoading: false,
          isLoaded: false,
          isSuccessful: false,
          taskLists: [],
        }
      default:
        return state
    }
  }