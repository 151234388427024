import {
    JUST_MINE_TRUE,
    JUST_MINE_FALSE,
  } from '../actions/types'
  
  const initialState = {
    justMine: false,
    }
  
  export default function justMineReducers (state = initialState, action) {
    switch (action.type) {
      case JUST_MINE_TRUE:
        return {
          justMine: true,
        }
      case JUST_MINE_FALSE:
        return {
          justMine: false,
        }
      default:
        return state
    }
  }