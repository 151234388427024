import * as React from 'react'
import { connect } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import { Avatar, Box, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip, Typography } from '@mui/material'
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';

import { logout } from '../../actions/auth'
import { getFamily } from '../../actions/family'

function Header(props) {
    const [family, setFamily] = React.useState(props.user.default_family)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    React.useEffect(() => {
        let data = { family_id: family }
        props.getFamily(data)
    }, [family])

    // const familySelect = props.user.families.filter(item => item.family_id !== family)
    const familySelect = props.user.families

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const navigate = useNavigate();

    const handleFamilyClick = async (family_id) => {
        await setFamily(family_id)
        navigate('/')
    }

    return (
        <React.Fragment>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'end',
                    boxShadow: 5,
                    marginBottom: 2,
                    borderBottom: 1,
                }}
            >
                <Tooltip title="Account settings">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar
                            sx={{ margin: 1 }}
                            alt={props.user.username}
                            src='/broken-image.jpg'
                        />
                    </IconButton>
                </Tooltip>
            </Box>

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {familySelect.map(f =>
                    <MenuItem key={f.family_id} onClick={(e) => handleFamilyClick(f.family_id, e)} disabled={f.family_id===family?true:false}>{f.name}</MenuItem>
                )}

                {familySelect.length > 0 ? <Divider /> : undefined}
                
                <MenuItem
                    component={Link}
                    to={'/MyAccount'}
                >
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Profile
                </MenuItem>
                <MenuItem onClick={props.logout}>
                    <ListItemIcon  sx={{color: '#b30000'}}>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    <Typography sx={{color: '#b30000'}}>Logout</Typography>
                </MenuItem>
            </Menu>

        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    user: state.authReducer.user,
    family: state.familyReducer.family,
})

export default connect(mapStateToProps, { logout, getFamily })(Header)