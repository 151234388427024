import React from 'react'
import { connect } from 'react-redux'
import store from '../../store'

import { Container, FormGroup, FormControlLabel, Grid, Switch, Typography } from '@mui/material'

import { getUserTasks } from '../../actions/tasks'
import { JUST_MINE_FALSE, JUST_MINE_TRUE } from '../../actions/types';

function TasksHeader(props) {
    const handleSwitchChange = () => {
        props.justMine ? store.dispatch({ type: JUST_MINE_FALSE }) : store.dispatch({ type: JUST_MINE_TRUE })
    }

    return (
        <React.Fragment>
            <Container maxWidth="sm" sx={{ py: 2 }}>
                <Grid container>
                    <Grid item xs={8}>
                        <Typography component="h1" variant="h5">{props.header}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={props.justMine}
                                        onChange={handleSwitchChange}
                                    // color="success"
                                    />
                                }
                                label={
                                    <Typography
                                        noWrap
                                        sx={{ fontSize: 11 }}
                                    >
                                        Just mine
                                    </Typography>
                                }
                                labelPlacement="start"
                                sx={{
                                    fontSize: 4
                                }}
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    user: state.authReducer.user,
    tasks: state.tasksReducer.tasks,
    loading: state.tasksReducer,
    justMine: state.justMineReducer.justMine,
    family: state.familyReducer.family,
})

export default connect(mapStateToProps, { getUserTasks })(TasksHeader)