import {
    FAMILY_LOADING,
    FAMILY_LOADED,
    FAMILY_SUCCESS,
    FAMILY_FAILED,
} from '../actions/types'

const initialState = {
    isLoading: true,
    isLoaded: false,
    family: {},
}

export default function scopeReducers(state = initialState, action) {
    switch (action.type) {
        case FAMILY_LOADING:
            return {
                ...state,
                isLoading: true,
            }
        case FAMILY_LOADED:
            return {
                ...state,
                isLoading: false,
                isLoaded: true,
                family: action.payload,
            }
        case FAMILY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoaded: false,
            }
        case FAMILY_FAILED:
            return {
                isLoading: false,
                isLoaded: false,
                family: {},
            }
        default:
            return state
    }
}