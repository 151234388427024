import * as React from 'react'
import { connect } from 'react-redux'

import Loading from '../common/Loading'
import TasksHeader from './TasksHeader';
import TaskLists from './TaskLists';

function TaskListsView(props) {
    if (props.family.isLoading) {
        return <Loading />
    } else {
        return (
            <React.Fragment>
                <TasksHeader
                    header={props.family.name + "'s tasks"}
                />
                <TaskLists />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    user: state.authReducer.user,
    tasks: state.tasksReducer.tasks,
    loading: state.tasksReducer,
    justMine: state.justMineReducer.justMine,
    family: state.familyReducer.family,
})

export default connect(mapStateToProps, {})(TaskListsView)