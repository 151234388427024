import * as React from 'react';
import { connect } from 'react-redux'
import Loading from '../common/Loading'
import TasksView from '../tasks/TasksView';


function Dashboard(props) {
    if (props.family.isLoading || props.task.taskAdding || props.task.taskDeleting || props.task.taskUpdating) {
        return <Loading />
    } else {
        return (
            <React.Fragment>
                <TasksView />
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    family: state.familyReducer,
    task: state.tasksReducer,
})

export default connect(mapStateToProps, {})(Dashboard)