import React, { useState } from 'react';
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
import store from '../../store';

import { Avatar, Box, Button, Checkbox, Container, FormControl, IconButton, InputLabel, ListItemText, MenuItem, Select, TextField } from '@mui/material';
import DeleteForever from '@mui/icons-material/DeleteOutline';

import { deleteTaskList, updateTaskList } from '../../actions/taskLists';
import { getUserTasks } from '../../actions/tasks'
import { getUserTaskLists } from '../../actions/taskLists'
import Tasks from './Tasks';
import TasksHeader from './TasksHeader';

function TaskListDetail(props) {
    const familyId = props.family.family_id

    const { taskListId } = useParams()

    const taskList = props.taskLists.filter((taskList) => taskList.task_list_id === taskListId)[0]

    const familyMembers = []
    props.family.members.map(member => familyMembers.push(member))

    const [collaborators, setCollaborators] = useState(taskList.collaborators ? taskList.collaborators : [])
    const [title, setTitle] = useState(taskList.title);
    const [description, setDescription] = useState(taskList.description ? taskList.description : '');

    const navigate = useNavigate();

    React.useEffect(() => {
        let data = {}
        props.justMine ? data = { user_id: props.user.user_id, family_id: familyId } : data = { family_id: familyId }
        store.dispatch(getUserTasks(data))
        store.dispatch(getUserTaskLists(data))
    }, [props.justMine])

    const taskListRelatedTasks = props.tasks.filter((task) => task.task_lists.includes(taskListId))
    // const overdue = { data: taskListRelatedTasks.filter(task => task.status === 'Overdue'), title: 'Overdue', open: false }
    // const today = { data: taskListRelatedTasks.filter(task => task.status === 'Today'), title: 'Today', open: true }
    // const future = { data: taskListRelatedTasks.filter(task => task.status === 'Upcoming' || task.status === 'Future' || task.status === 'Due soon'), title: 'Upcoming', open: false }
    const completed = { data: taskListRelatedTasks.filter(task => task.is_completed), title: 'Completed', open: false }
    const incomplete = { data: taskListRelatedTasks.filter(task => !task.is_completed), title: 'Incomplete', open: true }

    // const sortByStatus = [overdue, today, future, completed]
    const sortByStatus = [incomplete, completed]

    const handleCollaboratorChange = (event) => {
        setCollaborators(event.target.value);
    };

    const handleDeleteClick = () => {
        const data = { task_list_id: taskList.task_list_id }
        store.dispatch(deleteTaskList(data))
        navigate('/TaskLists')
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            task_list_id: taskList.task_list_id,
            title: title,
            description: description,
            collaborators: collaborators,
        }
        props.updateTaskList(data)
        navigate('/TaskLists')
    };

    return (
        <React.Fragment>

            <Container component='main' maxWidth='sm'>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >

                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        {/* DELETE BUTTON */}
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                            <IconButton onClick={handleDeleteClick} sx={{color: '#b30000'}}>
                                <DeleteForever />
                            </IconButton>
                        </Box>
                        
                        {/* COLLABORATORS */}
                        <FormControl fullWidth>
                            <InputLabel id="collaborators-select-label">Collaborators</InputLabel>
                            <Select
                                labelId="collaborators-select-label"
                                id="collaborators-select"
                                variant='standard'
                                multiple
                                value={collaborators}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map(item =>
                                                <Avatar
                                                    key={familyMembers.find(({ user_id: v }) => v === item).user_id}
                                                    alt={familyMembers.find(({ user_id: v }) => v === item).username}
                                                    src={'collaborators.img'}
                                                    sx={{ width: 30, height: 30 }}
                                                />
                                            )}
                                    </Box>
                                )}
                                // label="Collaborators"
                                onChange={handleCollaboratorChange}
                            >
                                {familyMembers.map(member =>
                                    <MenuItem key={member.user_id} value={member.user_id}>
                                        <Checkbox checked={collaborators.indexOf(member.user_id) > -1} />
                                        <Avatar
                                            key={member.user_id}
                                            alt={member.username}
                                            src={'collaborators.img'}
                                            sx={{ width: 30, height: 30, mr: 1 }}
                                        />
                                        <ListItemText primary={member.username} />
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>

                        {/* TITLE */}
                        <TextField
                            margin="normal"
                            variant='standard'
                            required
                            fullWidth
                            id="taskListTitle"
                            name="taskListTitle"
                            autoComplete="Task"
                            // autoFocus
                            onChange={e => setTitle(e.target.value)}
                            value={title}
                        />

                        {/* DESCRIPTION */}
                        <TextField
                            margin="normal"
                            fullWidth
                            multiline
                            rows={3}
                            id="taskListDescription"
                            label="Description"
                            name="taskListDescription"
                            autoComplete="Description"
                            onChange={e => setDescription(e.target.value)}
                            value={description}
                        />

                        {/* UPDATE BUTTON */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, bgcolor: '#000', '&:hover': { backgroundColor: '#333' } }}
                        >
                            Save
                        </Button>
                    </Box>
                </Box>
            </Container>

            <TasksHeader header={'Tasks'} />
            <Tasks sortBy={sortByStatus} familyId={familyId} />

        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    user: state.authReducer.user,
    tasks: state.tasksReducer.tasks,
    taskLists: state.taskListsReducer.taskLists,
    loading: state.tasksReducer,
    justMine: state.justMineReducer.justMine,
    family: state.familyReducer.family,
})

export default connect(mapStateToProps, { updateTaskList })(TaskListDetail)