import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from './store'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ScopedCssBaseline from '@mui/material/ScopedCssBaseline';

import { loadUser } from './actions/auth'
import Login from './components/accounts/Login'
import PrivateRoute from './components/common/PrivateRoute'
import Dashboard from './components/main/Dashboard';
import TaskDetailCard from './components/tasks/TaskDetailCard';
import AddTask from './components/tasks/AddTask';
import TaskListsView from './components/tasks/TaskListsView';
import AddTaskList from './components/tasks/AddTaskList';
import MyAccount from './components/accounts/MyAccount';
import TaskListDetail from './components/tasks/TaskListDetail';
import AddFamily from './components/accounts/AddFamily';

function App() {
  useEffect(() => {
    store.dispatch(loadUser())
  }, [])

  return (
    <ScopedCssBaseline>
      <Provider store={store}>
        <Router>
          <div>
            <Routes>
              <Route exact path='/MyAccount' element={<PrivateRoute />}>
                <Route exact path='/MyAccount' element={<MyAccount />} />
              </Route>
              <Route exact path='/Family/Add' element={<PrivateRoute />}>
                <Route exact path='/Family/Add' element={<AddFamily />} />
              </Route>
              <Route exact path='/TaskLists' element={<PrivateRoute />}>
                <Route exact path='/TaskLists' element={<TaskListsView />} />
              </Route>
              <Route exact path='/AddTask' element={<PrivateRoute />}>
                <Route exact path='/AddTask' element={<AddTask />} />
              </Route>
              <Route exact path='/AddTaskList' element={<PrivateRoute />}>
                <Route exact path='/AddTaskList' element={<AddTaskList />} />
              </Route>
              <Route exact path='/Task' element={<PrivateRoute />}>
                <Route exact path='/Task/:taskId' element={<TaskDetailCard />} />
              </Route>
              <Route exact path='/TaskList' element={<PrivateRoute />}>
                <Route exact path='/TaskList/:taskListId' element={<TaskListDetail />} />
              </Route>
              <Route exact path='/' element={<PrivateRoute />}>
                <Route exact path='/' element={<Dashboard />} />
              </Route>
              <Route exact path={'/Login'} element={<Login />} />
            </Routes>
          </div>
            <ToastContainer
              position="bottom-right"
              autoClose={3000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
        </Router>
      </Provider>
    </ScopedCssBaseline>
  );
}

export default App;
