import React, { useState } from 'react'

import { Card, CardActionArea, CardContent, Collapse, Grid, Typography } from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

function SectionHeaderCollapse(props) {

    const [open, setOpen] = useState(props.open)

    const expandCollapse = () => {
        setOpen(!open)
    }

    return (
        <React.Fragment>

            <Card sx={{ mb: 1, boxShadow: 0 }}>
                <CardActionArea onClick={expandCollapse}>
                    <CardContent sx={{ p: 1.5 }} >
                        <Grid container alignItems='center'>
                            <Grid>
                                <Typography>
                                    {props.title}
                                </Typography>
                            </Grid>
                            <Grid sx={{ ml: 'auto' }}>
                                <Typography>
                                    {props.data ? props.data.length : ''}
                                </Typography>
                            </Grid>
                            <Grid>
                                {open ? <ExpandLess /> : <ExpandMore />}
                            </Grid>
                        </Grid>
                    </CardContent>
                </CardActionArea>
            </Card>

            <Collapse in={open} timeout="auto" unmountOnExit>
                {props.children}
            </Collapse>

        </React.Fragment>
    )

}

export default SectionHeaderCollapse