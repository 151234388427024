import React, { useState } from 'react';
import store from '../../store'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Avatar, AvatarGroup, Box, Chip, IconButton, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'
import { getUserTasks, updateTask } from '../../actions/tasks';

const Task = (props) => {
    const [completed, setCompleted] = useState(props.isCompleted)

    const familyMembers = []
    props.user.families.map(family => family.members.map(member => familyMembers.push(member)))

    const handleClick = async () => {
        setCompleted(!completed);
        let task_id = props.taskId
        let title = props.title
        let is_completed = !completed

        const dataForUpdate = { task_id, title, is_completed }
        store.dispatch(updateTask(dataForUpdate))
        !completed === true ? toast.success('Congratulations!!') : toast.error('Oh no!')
        await new Promise(r => setTimeout(r, 500));
        const dataForTasks = { family_id: props.familyId }
        store.dispatch(getUserTasks(dataForTasks))
    }

    const generateChip = (status) => {
        const color = {
            'Complete': 'success',
            'Overdue': 'error',
            'Today': 'warning',
            'Due soon': 'info',
            'Upcoming': 'default'
        }

        return (
            <Chip
                label={status}
                color={color[status]}
                variant='outlined'
                size='small'
                sx={{
                    fontSize: 9,
                    display: 'flex',
                }}
            />
        )
    }

    return (
        <Box
            sx={{
                boxShadow: 3,
                borderBottom: 1,
                bgcolor: '#EEE',
                mb: .5,
                flexDirection: 'row',
                justifyContent: 'center',
                alignContent: 'center',
                fontSize: 11,
            }}
        >
            <Grid container alignItems='center' >

                <Grid xs={1} sx={{ pl: .5 }} >
                    <IconButton onClick={handleClick}>
                        {completed ? <CheckCircleIcon /> : <CheckCircleOutlineIcon />}
                    </IconButton>
                </Grid>

                <Grid xs={11}>

                    <Grid
                        container
                        alignItems='center'
                        component={Link}
                        to={`/Task/${props.taskId}`}
                        sx={{
                            p: 1
                        }}
                    >
                        <Grid xs={6} sx={{ mr: 'auto' }} >
                            <Typography noWrap sx={{ fontSize: 11, px: 1, width: 'auto', }} >
                                {props.title}
                            </Typography>

                        </Grid>

                        <Grid xs={3} >
                            {props.status !== 'Future' ? generateChip(props.status) : null}
                        </Grid>

                        <Grid >
                            <AvatarGroup>
                                {props.assignees.map(assignee =>
                                    <Avatar
                                        key={familyMembers.find(({ user_id: v }) => v === assignee).user_id}
                                        alt={familyMembers.find(({ user_id: v }) => v === assignee).username}
                                        src={'assignee.img'}
                                        sx={{ width: 24, height: 24 }}
                                    />
                                )}
                            </AvatarGroup>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Task