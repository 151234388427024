import * as React from 'react'
import { connect } from 'react-redux'

import { Container, Typography } from '@mui/material'

import Task from './Task'
import Section from '../common/Section';
import SectionHeaderCollapse from '../common/SectionHeaderCollapse';
import { getUserTasks } from '../../actions/tasks'

function Tasks(props) {

    return (
        <React.Fragment>

            <Container sx={{ p: 0, mb: 8 }}>
                {props.sortBy.map(section =>
                    <Section key={section.title} maxWidth='sm'>
                        <SectionHeaderCollapse
                            title={section.title}
                            data={section.data}
                            open={section.open}
                        >
                            {props.tasks.length > 0
                                ? section.data.sort((a, b) => Boolean(a.due_date) === Boolean(b.due_date) ? new Date(a.due_date) - new Date(b.due_date) : Boolean(b.due_date) - Boolean(a.due_date)).map(task =>
                                    <Task
                                        key={task.task_id}
                                        taskId={task.task_id}
                                        title={task.title}
                                        assignees={task.assignees}
                                        isCompleted={task.is_completed}
                                        dueDate={task.due_date}
                                        status={task.status}
                                        userId={props.user.userId}
                                        user={props.user}
                                        familyId={props.familyId}
                                    />
                                ) : <Typography variant='body2'>Create a task and start getting organised!</Typography>
                            }
                        </SectionHeaderCollapse>
                    </Section>
                )}
            </Container>

        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    user: state.authReducer.user,
    tasks: state.tasksReducer.tasks,
    loading: state.tasksReducer,
    justMine: state.justMineReducer.justMine,
    family: state.familyReducer.family,
})

export default connect(mapStateToProps, { getUserTasks })(Tasks)