import React, { useState } from 'react';
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom';

import { Avatar, Box, Button, Checkbox, Container, FormControl, InputLabel, ListItemText, MenuItem, Select, TextField } from '@mui/material';

import { addFamily } from '../../actions/family';
import PageHeader from '../common/PageHeader';

function AddFamily(props) {
    const familyMembersAll = []
    props.user.families.map(family => family.members.map(member => familyMembersAll.push(member)))
    const familyMembers = [...new Map(familyMembersAll.map(item => [item['id'], item])).values()]

    const [members, setMembers] = useState([props.user.user_id])
    const [name, setName] = useState('');
    
    const handleAssigneeChange = (event) => {
        setMembers(event.target.value);
    };

    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = {
            name: name,
            members: members,
        }
        props.addFamily(data)
        navigate('/MyAccount')
    };

    return (
        <React.Fragment>
            <PageHeader header="Start a new family" />

            <Container component='main' maxWidth='sm'>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        {/* NAME */}
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="familyName"
                            label="Family name"
                            name="familyName"
                            autoComplete="Family name"
                            autoFocus
                            onChange={e => setName(e.target.value)}
                            value={name}
                        />

                        {/* MEMBERS */}
                        <FormControl fullWidth>
                            <InputLabel id="members-select-label">Members</InputLabel>
                            <Select
                                labelId="members-select-label"
                                id="members-select"
                                multiple
                                value={members}
                                renderValue={(selected) => (
                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                        {selected.map(item =>
                                            <Avatar
                                                key={familyMembers.find(({ user_id: v }) => v === item).user_id}
                                                alt={familyMembers.find(({ user_id: v }) => v === item).username}
                                                src={'assignee.img'}
                                                sx={{ width: 30, height: 30 }}
                                            />
                                        )}
                                    </Box>
                                )}
                                label="Members"
                                onChange={handleAssigneeChange}
                            >
                                {familyMembers.map(member =>
                                    <MenuItem key={member.user_id} value={member.user_id}>
                                        <Checkbox checked={members.indexOf(member.user_id) > -1} />
                                        <Avatar
                                            key={member.user_id}
                                            alt={member.username}
                                            src={'assignee.img'}
                                            sx={{ width: 30, height: 30, mr: 1 }}
                                        />
                                        <ListItemText primary={member.username} />
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>

                        {/* CREATE BUTTON */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2, bgcolor: '#000', '&:hover': { backgroundColor: '#333' } }}
                        >
                            Start
                        </Button>
                    </Box>
                </Box>
            </Container>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    user: state.authReducer.user,
    taskLists: state.taskListsReducer.taskLists,
    family: state.familyReducer.family,
})

export default connect(mapStateToProps, { addFamily })(AddFamily)