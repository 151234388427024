export const AUTH_ERROR = "AUTH_ERROR";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";

export const FAMILY_LOADING = "FAMILY_LOADING";
export const FAMILY_LOADED = "FAMILY_LOADED";
export const FAMILY_SUCCESS = "FAMILY_SUCCESS";
export const FAMILY_FAILED = "FAMILY_FAILED";

export const TASKS_LOADING = "TASKS_LOADING";
export const TASKS_LOADED = "TASKS_LOADED";
export const TASKS_SUCCESS = "TASKS_SUCCESS";
export const TASKS_FAILED = "TASKS_FAILED";

export const TASK_ADDING = "TASK_ADDING";
export const TASK_ADDED = "TASK_ADDED";

export const TASK_DELETING = "TASK_DELETING";
export const TASK_DELETED = "TASK_DELETED";

export const TASK_UPDATING = "TASK_UPDATING";
export const TASK_UPDATED = "TASK_UPDATED";

export const TASK_LISTS_LOADING = "TASK_LISTS_LOADING";
export const TASK_LISTS_LOADED = "TASK_LISTS_LOADED";
export const TASK_LISTS_SUCCESS = "TASK_LISTS_SUCCESS";
export const TASK_LISTS_FAILED = "TASK_LISTS_FAILED";

export const JUST_MINE_TRUE = "JUST_MINE_TRUE";
export const JUST_MINE_FALSE = "JUST_MINE_FALSE";
